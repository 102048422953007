import React, { useCallback, useEffect, useState } from 'react';
import MainMenu from "../mainmenu/MainMenu";
import './styles.scss'
import './mobilestyles.scss'
import PageItem from "../pageitem/PageItem";
import logo from './assets/renew-landscapes.png'
import PageContent from "../pagecontent/PageContent";
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'animate.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Gallery } from "react-grid-gallery";
import ImageGallery from 'react-image-gallery';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import axios from "axios";
import ls1Img from "./assets/ls1.jpg";
import Gallery1 from './assets/Gallery1.jpg'
import Gallery2 from './assets/Gallery2.jpg'
import Gallery3 from './assets/Gallery3.jpg'
import Gallery4 from './assets/Gallery4.jpg'
import Gallery5 from './assets/Gallery5.jpg'
import Gallery6 from './assets/Gallery6.jpg'
import Gallery7 from './assets/Gallery7.jpg'
import Gallery8 from './assets/Gallery8.jpg'
import Contact1 from './assets/contact1.jpg'


//   [
//
// //   {
// //   src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
// //   width: 320,
// //   height: 174,
// //   caption: "After Rain (Jeshu John - designerspics.com)",
// // }, {
// //   src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
// //   width: 320,
// //   height: 212,
// //   tags: [{value: "Ocean", title: "Ocean"}, {value: "People", title: "People"},],
// //   alt: "Boats (Jeshu John - designerspics.com)",
// // },
//
//   {
//     src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg", width: 320, height: 212,
//   },
//
// ];


const MainPage = ({ defaultIndex = null, isShowContactDefault = false, isShowGalleryDefault = false }) => {
  const [showPageId, setShowPageId] = useState(defaultIndex);
  const [isShowContact, setIsShowContact] = useState(isShowContactDefault);
  const [isShowGallery, setIsShowGallery] = useState(isShowGalleryDefault);
  const [isShowMobileMenu, setIsShowMobileMenu] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [timeKey, setTimeKey] = useState(+(new Date()));

  const navigate = useNavigate();

  let location = useLocation();

  React.useEffect(() => {
    console.info('location', location);

    switch (location.pathname) {
      case '/':
        setShowPageId(null);
        setIsShowContact(null);
        setIsShowGallery(false);
        break;
      case '/about':
        setShowPageId(1);
        setIsShowContact(null);
        setIsShowGallery(false);
        break;
      case '/design':
        setShowPageId(2);
        setIsShowContact(null);
        setIsShowGallery(false);
        break;
      case '/services':
        setShowPageId(3);
        setIsShowContact(null);
        setIsShowGallery(false);
        break;
      case '/blog':
        setShowPageId(4);
        setIsShowContact(null);
        setIsShowGallery(false);
        break;
      case '/contact':
        setShowPageId(null);
        setIsShowContact(true);
        setIsShowGallery(false);
        break;
      case '/gallery':
        setShowPageId(null);
        setIsShowContact(false);
        setIsShowGallery(true);
        break;

      default:
        setShowPageId(null);
        setIsShowContact(null);
    }

  }, [location]);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, [])


  const toggleContact = useCallback(() => {
    setIsShowContact(!isShowContact);
  }, [isShowContact])


  const submitContactForm = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    // const request = new XMLHttpRequest();

    const form = document.getElementById('contactForm');
    const formData = new FormData(form);
    // request.open('POST', 'https://igycrmezikdhh5yqlhr2tpkfl40jlwsk.lambda-url.us-east-1.on.aws');
    // request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    //
    const formDataObj = {};
    formData.forEach((value, key) => (formDataObj[key] = value));


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(formDataObj);

    var requestOptions = {
      method: 'POST', headers: myHeaders, body: raw, redirect: 'follow'
    };

    axios.post("https://igycrmezikdhh5yqlhr2tpkfl40jlwsk.lambda-url.us-east-1.on.aws", formDataObj)
      .then(result => {
        alert('Thank you for your message. We will contact you shortly.');
      })

    // fetch("https://igycrmezikdhh5yqlhr2tpkfl40jlwsk.lambda-url.us-east-1.on.aws", requestOptions)
    //   .then(response => response.text())
    //   .then(result => {
    //     alert('Thank you for your message. We will contact you shortly.');
    //   })
    //   .catch(error => console.log('error', error));


    return false;
  }, [])

  return <div>

    {!!currentImage && (<Lightbox
      // key={`${timeKey}_gallery`}
      // mainSrc={currentImage.src}
      // imageTitle={''}
      // mainSrcThumbnail={currentImage.src}
      // onCloseRequest={() => {
      //   setCurrentImage(null);
      // }}
      open={true}
      close={() => setCurrentImage(null)}
      slides={[{ src: currentImage.src },]}
    />)}

    <div className={`top-contact-container ${isShowContact && 'active'}`}>
      <div className="contact-flex">
        <div className="lines">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <div className="contact-close-button cursor-pointer" onClick={() => {
          navigate("/");
        }}>
          <span style={{color:'white',fontWeight:'bold'}}>X</span>
        </div>
     
        <div  className="contact-form">
        <div className="contact-side-image">
            <img className="contact-side-img " src={Contact1}/>
          </div>
          <div className="contact-content">
            <div>
              <div className="text-start contact-title-screen">
                <p style={{fontFamily:'cursive'}} className="contact-title d-inline-block">Contact</p>
              </div>
              <form id="contactForm" action="/submit" method="post" onSubmit={submitContactForm}>
                <div className="container">
                  <div className="row mb-1">
                    <div className="col-md-6">
                      <div className="form-group"><input type="text" placeholder="First Name" name="firstName"
                        required="required" className="form-control" />

                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group"><input type="text" placeholder="Last Name" name="lastName"
                        required="required" className="form-control" /></div>
                    </div>
                  </div>
                  <div className="row mb-1">
                    <div className="col-md-6">
                      <div className="form-group"><input type="email" placeholder="Enter email" name="contactEmail"
                        required="required" className="form-control" /></div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group"><input type="tel" placeholder="Phone" name="phone"
                        required="required"
                        className="form-control" /></div>
                    </div>
                  </div>
                  <div className="row mb-1">
                    <div className="col-md-6">
                      <div className="form-group"><textarea type="text" placeholder="Message" name="emailBody"
                        required="required" className="form-control" />
                      </div>
                    </div>
                  </div>

                </div>
                <div className="text-start mt-2 ms-2 contact-btn-screen">
                  <button type="submit" className="btn contact-submit-btn mt-1">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className={`top-contact-container gallery-container ${isShowGallery && 'active'}`}>
      <div className="contact-flex">

        <div className="contact-close-button cursor-pointer" onClick={() => {
          navigate("/");
        }}>
          X
        </div>

        <div style={{ overflowY: 'scroll', gap: '1em' }} className="w-100 h-100 d-flex flex-column align-items-center">

          <div className="tab-container">
            <div className="single-tab">Before</div>
            <div className="single-tab">After</div>
          </div>

          <img className="img-gallery" src={Gallery1} />
          <img className="img-gallery" src={Gallery2} />
          <img className="img-gallery" src={Gallery3} />
          <img className="img-gallery" src={Gallery4} />
          <img className="img-gallery" src={Gallery5} />
          <img className="img-gallery" src={Gallery6} />
          <img className="img-gallery" src={Gallery7} />
          <img style={{marginBottom:'3em'}} className="img-gallery" src={Gallery8} />
   
   
          {/* <ImageGallery showFullscreenButton={false} showPlayButton={false}  items={images} onClick={index => {
            setCurrentImage(images[index]);
          }}/> */}
        </div>
      </div>
    </div>

    <div className="home-page d-block d-lg-none home-page-mobile">


      {isShowMobileMenu && <div data-aos="fade-up" id="mobileMenu" className="mobile-menu active">
        <div className="text-left panel-div">

          <Link onClick={() => {
            setIsShowMobileMenu(false);
          }} className="mobile-menu-panel-item active no-text-decor" type="span" to="/about">About</Link>

          <Link onClick={() => {
            setIsShowMobileMenu(false);
          }} className="mobile-menu-panel-item active no-text-decor" type="span" to="/design">Design</Link>


          <Link onClick={() => {
            setIsShowMobileMenu(false);
          }} className="mobile-menu-panel-item active no-text-decor" type="span" to="/services">Services</Link>

          <Link onClick={() => {
            setIsShowMobileMenu(false);
          }} className="mobile-menu-panel-item active no-text-decor" type="span" to="/blog">Blog</Link>


          <Link onClick={() => {
            setIsShowMobileMenu(false);
          }} className="mobile-menu-panel-item active no-text-decor" type="span" to="/gallery">Gallery</Link>


        </div>
      </div>}


      <div className="mobile-header p-2">
        <div className="header-box"><a href="/"><img
          style={{
            width: '4em'
          }}
          src="/mainlogo.png?5c45649fb27af8d2b4440a5366a44c58"
          alt="" /></a></div>

        <Link onClick={() => {
          setIsShowMobileMenu(false);
        }} className="header-box no-text-decor" type="span" to="/contact">
          <div className="header-box"><i className="fas fa-envelope fa-2x contact-button"></i></div>
        </Link>


        <div className="header-box"><a href="tel:5624248273"><i className="fas fa-phone fa-2x"></i></a></div>
        <div className="header-box" onClick={() => {
          setIsShowMobileMenu(!isShowMobileMenu)
        }}><i className="fas fa-bars fa-2x"></i></div>
      </div>

      <div className={`col-holder ${showPageId !== null && 'active'}`}>
        <PageItem isForMobile={true} itemIndex={1} onClickCallback={setShowPageId} currentShowIndex={showPageId} />
        <PageItem isForMobile={true} itemIndex={2} onClickCallback={setShowPageId} currentShowIndex={showPageId} />


        <div className={`w-100 text-center main-menu-mobile ${showPageId !== null && 'd-none'}`}>
          <div className="w-100 text-center big-text mt-3">
            CREATE. ENHANCE. MAINTAIN.
          </div>
          <img src={logo} alt="" className="mb-3 mobile-logo" />
        </div>

        <PageItem isForMobile={true} itemIndex={3} onClickCallback={setShowPageId} currentShowIndex={showPageId} />
        <PageItem isForMobile={true} itemIndex={4} onClickCallback={setShowPageId} currentShowIndex={showPageId} />
      </div>
    </div>

    <div className="home-page d-none d-lg-block">
      <div className={`col-holder ${showPageId !== null && 'active'}`}>
        <PageItem itemIndex={1} onClickCallback={setShowPageId} currentShowIndex={showPageId} />
        <PageItem itemIndex={2} onClickCallback={setShowPageId} currentShowIndex={showPageId} />
        <MainMenu toggleContact={toggleContact} isActive={showPageId !== null || isShowContact || isShowGallery}
          onClickCallback={setShowPageId}></MainMenu>
        <PageItem itemIndex={3} onClickCallback={setShowPageId} currentShowIndex={showPageId} />
        <PageItem itemIndex={4} onClickCallback={setShowPageId} currentShowIndex={showPageId} />
      </div>
    </div>


    {showPageId !== null && <>
      <div className="content-area w-100">

        <div className="d-block d-lg-none">
          <div className="all-lines">
            <div className="line"></div>
          </div>
          <PageContent isForMobile={true} currentShowIndex={showPageId} />
        </div>

        <div className="d-none d-lg-block">
          <div className="all-lines">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
          <PageContent currentShowIndex={showPageId} />
        </div>
      </div>
      <div className="footer d-flex flex-column pb-3 pt-3">
        <div className="d-flex">
          <Link className="no-text-decor" type="span" to="/about">
            <span>About</span>
          </Link>

          <Link className="no-text-decor" type="span" to="/design">
            <span>Design</span>
          </Link>

          <Link className="no-text-decor" type="span" to="/services">
            <span>Services</span>
          </Link>

          <Link className="no-text-decor" type="span" to="/blog">
            <span>Blog</span>
          </Link>

          <Link className="no-text-decor" type="span" to="/gallery">
            <span>Gallery</span>
          </Link>

        </div>
        <div>Copyright © 2022 Savage Landscapes
        </div>
      </div>


    </>


    }
  </div>

}

MainPage.propTypes = {};

export default MainPage;
