import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import MainPage from "./components/mainpage/MainPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
  },
  {
    path: "/about",
    element: <MainPage defaultIndex={1} />,
  },
  {
    path: "/design",
    element: <MainPage defaultIndex={2} />,
  },
  {
    path: "/services",
    element: <MainPage defaultIndex={3} />,
  },
  {
    path: "/blog",
    element: <MainPage defaultIndex={4} />,
  },
  {
    path: "/contact",
    element: <MainPage defaultIndex={null} isShowContactDefault={true} />,
  },
  {
    path: "/gallery",
    element: <MainPage defaultIndex={null} isShowContactDefault={false} isShowGalleryDefault={true} />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
