import React from 'react';
import './styles.scss'
import HeaderItem from "./HeaderItem";
import MainTitleQuote from "./MainTitleQuote";
import Logo from '../mainpage/assets/renew-landscapes.png'
import houzzIcon from './assets/houzz-icon.jpg'
import fbIcon from './assets/facebook-icon.jpg'
import instagramIcon from './assets/instagram-icon.jpg'
import veggieGarden from './assets/veggieGarden.jpg'
import imagels1 from './assets/ls1.jpg'
import imagels2 from './assets/ls2.jpg'
import irriImage1 from './assets/irri1.jpg'
import irriImage2 from './assets/irri2.jpg'
import Design1 from './assets/Design1.jpg'
import Design2 from './assets/Design2.jpg'
import Design3 from './assets/Design3.jpg'
import Design4 from './assets/Design4.jpg'
import Design5 from './assets/Design5.jpeg'
import Design6 from './assets/Design6.jpeg'
import Design7 from './assets/Design7.jpeg'
import Design8 from './assets/Design8.jpeg'
import Design9 from './assets/Design9.jpeg'
import Design10 from './assets/Design10.jpeg'
import Design12 from './assets/Design12.jpeg'
// import Design9 from './assets/Design9.jpeg'
import Design13 from './assets/Design13.jpeg'
import Services1 from './assets/Services1.jpeg'
import Services2 from './assets/Services2.jpeg'
import Services3 from './assets/Services3.jpeg'
import Services4 from './assets/Services4.jpeg'
import About1 from './assets/About1.jpeg'
import About2 from './assets/About2.jpg'


const BlogPageContent = (

  <>
    <div className="row pt-5 ms-0 me-0">
      <div className="col-12 col-md-3 d-flex justify-content align-items-center align-content-center text-center">
        <div className="w-100">
          <HeaderItem>
            <>
              <p className="green-text big text-center">GETTING THE ITCH? PLAN YOUR VEGETABLE GARDEN NOW

              </p>
              <p className="green-text text-center">by Sarah Savage

              </p>
            </>
          </HeaderItem>
        </div>
      </div>
      <div className="col-6"><img
        src="/blog1.png" alt=""
        data-aos="fade-up" width="100%" className="aos-init aos-animate" /></div>
      <div className="col-6 col-md-3 d-flex justify-content align-items-center">
        <div>

          <p className="raw-text" data-aos="fade-up">It’s mid January, the skies are grey, that west coast
            rain just doesn’t want to stop and all you are thinking
            about is that beautiful warm weather and fresh
            veggies.
            <br />
            <br />
            Let’s face it, with the rising cost of fresh produce,
            growing your own vegetables is becoming more and
            more popular. There is no more rewarding feeling
            than cutting open a fresh tomato from the garden,
            that you grew yourself.
            <br />
            <br />
            Not only will you save money but you will have fresh
            produce that tastes better than anything you’ve bought from any grocery store.
            You know when you’re in the middle of cooking dinner, and realize you forgot
            something for your recipe?
            <br />
            <br />
            NO STRESS!
            <br />
            <br />
            You don’t have to run to the shop, just pop out to the garden and grab what you
            need.
          </p>
        </div>
      </div>


    </div>

    <div className="row pt-5 ms-0 me-0">

      <div className="col-12 col-md-3">
        <img
          src="/blog2.png" alt=""
          data-aos="fade-up" width="100%" className="aos-init aos-animate" />
        <div className="text-center"><small>Fresh beans from the garden</small></div>
      </div>


      <div className="col-12 col-md-3 d-flex justify-content align-items-center">
        <div>

          <p className="raw-text" data-aos="fade-up">
            So let's dig into the main steps of planning
            your vegetable garden.
            Here are some helpful tips on having a
            successful garden, whether you have an
            existing garden or are starting one from
            scratch.
            <br />
            <br />
            I will add that if you do feel overwhelmed
            with everything that goes into planning and
            or building your vegetable garden, you always
            have the option of hiring a professional, and
            that's ok too!
          </p>
        </div>
      </div>


    </div>

    <div className="row pt-5 ms-0 me-0">

      <div className="col-12 col-md-6 d-flex justify-content align-items-center align-content-center text-center">
        <div className="w-100">
          <HeaderItem>
            <>
              <p className="green-text big text-center">Okay let’s get to planning!!

              </p>
              <p className="green-text text-center">

              </p>
            </>
          </HeaderItem>
        </div>
      </div>

      <div className="col-12 col-md-3 d-flex justify-content align-items-center">
        <div>

          <p className="raw-text" data-aos="fade-up">
            <b>LOCATION</b>: The first thing you will want to do is figure out where you will build
            your bed. Keep your eye out for the most sunny spot on your property during the
            summer months, when the sun is highest in the sky. Most vegetables will need full
            sun for best production, this means 6+ hours of sunlight per day. If you happen to
            have an area that doesn’t get full sun, but just part sun, that’s ok too! There are
            plenty of veggies, like those leafy greens, that love part shade, so just take that into
            account when planning the layout of the bed.
          </p>

          <p className="raw-text" data-aos="fade-up">
            <b>BED TYPE</b>: Now that you have figured out the location of your bed, it is time to
            figure what type of bed you were planning on. Did you want a normal bed? A raised
            bed? Do you live in an apartment building? Container gardening is also a great
            alternative if you are limited for space!
          </p>
          <p className="raw-text" data-aos="fade-up">
            <b>BUILD IT</b>: So you know what you want, it is time to build it.
            If you are doing a raised bed try to use a material that hasn’t been treated. Ideally, I
            like to use cedar to build my raised beds. Not only does it last longer, it also helps to
            repel certain pests from your garden.
            <br />
            <br />
            If you are planning to do container
            gardening, try to choose deeper planters
            that will allow more space for your plants
            to grow, and keep them from drying out
            faster. After you have brought home your
            planters make sure to check and see if
            they have drainage. I find a lot of planters
            these days don’t have drainage holes in
            them, but this is an easy fix. All you need
            is a power drill and a half inch bit. Drill
            three to five holes in the bottom of the
            planter, this allows for adequate
            drainage.
          </p>

        </div>
      </div>

      <div className="col-12 col-md-6"><img
        src="/blog3.png" alt=""
        data-aos="fade-up" width="100%" className="aos-init aos-animate" /></div>

    </div>


    <div className="row pt-5 ms-0 me-0">

      <div className="col-12 d-flex justify-content align-items-center">
        <div>
          <p className="raw-text" data-aos="fade-up">
            <strong>SOIL</strong>: Now to get to the dirt! I like to use a nice deluxe garden mix, this consists
            of
            compost, enriched loam and small pebbles to aid in drainage. I also like to add sea
            soil for an extra boost of nutrients.
            If you are container gardening, you can go to your local nursery and pick up some
            nice nutrient rich potting mix for your planters.


          </p>

          <p className="raw-text" data-aos="fade-up">
            <strong>CHOOSING WHAT YOU WANT TO GROW</strong>: Now for the fun part, deciding on
            what you want to grow! What vegetables, herbs or fruits do you use often in your
            cooking? Keep in mind what needs full sun and what can take partial shade, so you
            can decide what goes where.
          </p>
        </div>
      </div>

    </div>

    <div className="row pt-5 ms-0 me-0">

      <div className="col-6 d-flex justify-content align-items-center">
        <div>
          <p className="raw-text" data-aos="fade-up">
            Once you know what you want to grow, you can
            decide whether you want to start from seed or
            will you be purchasing starts, to direct sow.
            If you are starting from seed, there are many
            certified organic seed suppliers to choose from.
            I have always used Urban Harvest out of
            Ontario, but since moving to the island I am a
            big fan of West Coast Seeds. They also have a
            very handy Regional planting chart available
            online, so you know what to plant out when.
            <br />
            <br />
            <strong>TIP</strong>: Make sure to follow the directions on the
            seed packet of when to start your seeds indoors
            or when you direct sow them in the bed.
          </p>

        </div>
      </div>


      <div className="col-6">
        <img
          src="/img.png" alt=""
          data-aos="fade-up" width="100%" className="aos-init aos-animate" />
        <div className="text-center"><small></small></div>
      </div>

    </div>


    <div className="row pt-5 ms-0 me-0">
      <div className="col-12 col-md-9">
        <img
          src="/img_1.png" alt=""
          data-aos="fade-up" width="100%" className="aos-init aos-animate" />
        <div className="text-center"><small>Seeds chosen to direct sow
        </small></div>
      </div>
      <div className="col-12 col-md-3 d-flex justify-content align-items-center">
        <div>
          <p className="raw-text" data-aos="fade-up">
            If you plan to purchase
            your starts, check out
            your local nursery, they
            usually have starts
            available beginning of
            May. If you do decide to
            grab your starts early,
            then you can keep them
            in a protected
            space during the day
            and bring them in at
            night.
          </p>

        </div>
      </div>


    </div>


    <div className="row pt-5 ms-0 me-0">
      {/*<div className="col-9">*/}
      {/*  <img*/}
      {/*    src="/img_1.png" alt=""*/}
      {/*    data-aos="fade-up" width="100%" className="aos-init aos-animate"/>*/}
      {/*  <div className="text-center"><small>Seeds chosen to direct sow*/}
      {/*  </small></div>*/}
      {/*</div>*/}
      <div className="col-12 d-flex justify-content align-items-center">
        <div>
          <p className="raw-text" data-aos="fade-up">
            There are some things that can be planted out earlier in the cooler temperatures,
            but as a rule of thumb for most vegetables, do not plant out until May 24 or after
            the threat of frost has passed. If you do choose to plant out a bit before that date,
            keep in mind you will need to use reemay cloth or cloches to protect your plants
            from frost at night.
            <br />
            <br />
            <strong>TIP</strong>: As you are planting out, sprinkle a small amount of bone meal into the hole.
            This gives an extra little kick start and encourages strong roots.
          </p>
        </div>
      </div>
    </div>

    <div className="row pt-5 ms-0 me-0">
      <div className="col-12">
        <img
          src="/img_2.png" alt=""
          data-aos="fade-up" width="100%" className="aos-init aos-animate" />
        <div className="text-center"><small>Laying out the vegetables before planting
        </small></div>
      </div>
      {/*<div className="col-12 d-flex justify-content align-items-center">*/}
      {/*  <div>*/}
      {/*    <p className="raw-text" data-aos="fade-up">*/}
      {/*      There are some things that can be planted out earlier in the cooler temperatures,*/}
      {/*      but as a rule of thumb for most vegetables, do not plant out until May 24 or after*/}
      {/*      the threat of frost has passed. If you do choose to plant out a bit before that date,*/}
      {/*      keep in mind you will need to use reemay cloth or cloches to protect your plants*/}
      {/*      from frost at night.*/}
      {/*      <br/>*/}
      {/*      <br/>*/}
      {/*      <strong>TIP</strong>: As you are planting out, sprinkle a small amount of bone meal into the hole.*/}
      {/*      This gives an extra little kick start and encourages strong roots.*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>

    <div className="row pt-5 ms-0 me-0">
      {/*<div className="col-12">*/}
      {/*  <img*/}
      {/*    src="/img_2.png" alt=""*/}
      {/*    data-aos="fade-up" width="100%" className="aos-init aos-animate"/>*/}
      {/*  <div className="text-center"><small>Laying out the vegetables before planting*/}
      {/*  </small></div>*/}
      {/*</div>*/}
      <div className="col-12 d-flex justify-content align-items-center">
        <div>
          <p className="raw-text" data-aos="fade-up">
            MULCHING: Once you’ve got everything planted in, it’s time to mulch your beds! I
            like to use a nice fine bark mulch, and apply about three inches. Keep in mind if you
            have sown seeds to keep the mulch back from where they will be popping through
            the soil. Once they are large enough you can lightly spread the mulch around them.
            Mulch not only helps with weeds, but also retains moisture, which will keep your
            plants from drying out.
            <br />
            <br />
            Now that your vegetable garden is planted, all you have to do is weed, water and
            watch your plants grow, and if you want to give them a little organic fertilizer,
            that’s great too!
            <br />
            <br />
            You will find you will get into a little routine with your garden, I certainly have.
            My favourite thing is to go wander in my vegetable garden every morning with a
            cup of coffee and check on the progress of my plants.
            This way I can pull any weeds, water (because we want to water early morning or in
            the evening, as
            to not burn the plants), sucker the tomato plants if needed, admire the beautiful
            dew covered web of my orbweaver spider and watch the honey bees pollinating the
            flowers of my soon to be veggies!
          </p>
        </div>
      </div>
    </div>


    <div className="row pt-5 ms-0 me-0">
      <div className="col-6">
        <img
          src="/img_3.png" alt=""
          data-aos="fade-up" width="100%" className="aos-init aos-animate" />
        <div className="text-center"><small>Orbweaver in amongst the tomato plants
        </small></div>
      </div>
      <div className="col-3 d-flex justify-content align-items-center">
        <div>
          <p className="raw-text" data-aos="fade-up">
            Before you know it the sun will be shining and
            you’ll be enjoying all your fresh produce, and yes
            it does taste better if you’ve grown it yourself.
            Links:
            <br />
            <br />
            <a href="https://www.westcoastseeds.com" target="_blank">https://www.westcoastseeds.com</a>
            <br />
            <a href="https://uharvest.ca" target="_blank">https://uharvest.ca</a>

          </p>
        </div>
      </div>
    </div>


    {/*// tan*/}
    <div className="row pt-5 ms-0 me-0">
      <div className="col-12 col-md-3"></div>
      <div className="col-md-6 d-flex justify-content align-items-center align-content-center text-center">
        <div className="w-100">
          <HeaderItem>
            <>
              <p className="green-text big text-center">FALL & SPRING CLEAN-UP: IN 5 EASY STEPS

              </p>
              <p className="green-text text-center">by Sarah Savage

              </p>
            </>
          </HeaderItem>
        </div>
      </div>
    </div>

    <div className="row pt-5 ms-0 me-0">

      <div className="col-12 col-md-6"><img
        src="/img_10.png" alt=""
        data-aos="fade-up" width="100%" className="aos-init aos-animate" /></div>

      <div className="col-12 col-md-3 d-flex justify-content align-items-center">
        <div>

          <p className="raw-text" data-aos="fade-up">

            There is no better
            feeling than putting
            your gardens to bed
            for the winter. Yes it
            is a bit of a chore, but
            once it's complete it
            will set your mind at
            ease, knowing you
            have less work to do
            come spring!
            <br />
            <br />
            If you’re not able to
            get the yard and
            gardens cleaned up in the fall, that’s ok too! Early spring is also a good time, ideally
            before everything starts waking up. You don't want to damage that new growth on
            those beautiful spring bulbs and perennials.


          </p>
        </div>
      </div>


    </div>

    <div className="row pt-5 ms-0 me-0">

      <div className="col col-md-3"></div>
      <div className="col-12 col-md-6"><img
        src="/img_11.png" alt=""
        data-aos="fade-up" width="100%" className="aos-init aos-animate" />

        <div className="text-center"><small>Daffodil in bloom
        </small></div>

      </div>

      <div className="col-12 col-md-3 d-flex justify-content align-items-center">
        <div>

          <p className="raw-text" data-aos="fade-up">

            Tidying up your yard isn’t just
            aesthetically pleasing, but also has its
            benefits too!
            <br />
            <br />
            What is involved in a fall cleanup, you
            ask?
            <br />
            Here are 5 steps for an efficient fall
            cleanup.
            <br />
            <br />
            <strong>1. WEEDING:</strong>
            Weeding your beds before winter helps
            reduce the workload and allows for a
            fresh start come spring. But if you're
            like me, itching to get out into the
            garden as soon as possible, you can
            leave the weeds for spring once the
            ground thaws.


          </p>
        </div>
      </div>


    </div>

    <div className="row pt-5 ms-0 me-0">

      <div className="col-12 d-flex justify-content align-items-center">
        <div>

          <p className="raw-text" data-aos="fade-up">

            <strong>2. PERENNIAL CUTBACK:</strong>
            Cutting back your perennials once they are finished allows for the fresh growth to
            come up right away, once they come out of dormancy. You will also find it much
            easier to cut back in the fall before everything flops over and gets mushy. I like to
            leave a couple inches above the soil so I know where everything is.
            TIP: Try leaving things like echinacea a little longer so the birds can enjoy those
            tasty seeds!
            <br />
            <br />
            <strong>3. REMOVE ANNUALS:</strong>
            You will want to remove any annuals from your beds and planters. I always feel a
            little bad doing this, but they won’t survive the winter. Try to do this in the fall
            before they start decomposing, trust me, you’ll be glad you did.
            <br />
            <br />
            <strong>4. PRUNING:</strong>
            This is a great time to get your shrubs to the size you like and remove dead and or
            crossing branches. This prevents more damage in a snowfall and encourages new
            growth once they come out of dormancy. Although keep in mind that certain
            shrubs will have to wait until late spring after they finish flowering.
            <br />
            <br />
            <strong>5. LEAF & DEBRIS REMOVAL:</strong>
            I personally prefer to clean up the leaves and branches in the fall. Wouldn’t you
            rather pick up nice dry light leaves, rather than heavy soaking wet leaves? Work
            smart, not hard am I right?
            Removal of fallen leaves helps to reduce the spread of disease and allows the
            gardens and lawn to breathe. This will help to keep your lawn free of yellowing and
            dead spots.


          </p>
        </div>
      </div>


    </div>

    <div className="row pt-5 ms-0 me-0">

      <div className="col-12 col-md-6"><img
        src="/img_12.png" alt=""
        data-aos="fade-up" width="100%" className="aos-init aos-animate" />

        <div className="text-center"><small>Lawn damage from leaves</small></div>
      </div>

      <div className="col-12 col-md-6 d-flex justify-content align-items-center">
        <div>

          <p className="raw-text" data-aos="fade-up">

            <strong>TIP:</strong> If you’d like, you can leave a few small leafy
            spots out of the way for hibernating insects to
            shelter for winter.
            <br />
            <br />
            You might ask, but how do you help to insulate
            the plants in the colder temperatures if you
            remove all the leaves from the beds?
            One word, MULCH.
            <br />
            <br />
            I am a firm believer in mulching your beds, but
            we’ll touch base on that one in a later blog.


          </p>
        </div>
      </div>
    </div>

    <div className="row pt-5 ms-0 me-0">

      <div className="col-12 col-md-6"><img
        src="/img_13.png" alt=""
        data-aos="fade-up" width="100%" className="aos-init aos-animate" />

        <div className="text-center"><small>Dark eyed Junco and humming bird enjoying a winter meal
        </small></div>
      </div>

      <div className="col-12 col-md-6 d-flex justify-content align-items-center">
        <div>

          <p className="raw-text" data-aos="fade-up">

            <strong>*FEED THE BIRDS:</strong> This is just a little extra that I always do. I like to keep my
            hummingbird feeder and
            bird feeder filled at all times
            in the winter months. They
            don’t have all the flowers
            and treats they would in the
            warmer seasons. This is my
            way of thanking them for
            pollinating my plants and
            for keeping the pests under
            control.


            <img
              src="/img_14.png" alt=""
              data-aos="fade-up" width="100%" className="aos-init aos-animate" />


          </p>
        </div>
      </div>
    </div>

    <div className="row pt-5 ms-0 me-0">

      <div className="col-12 col-md-12 d-flex justify-content align-items-center">
        <div>

          <p className="raw-text" data-aos="fade-up">

            Ok! Now that that’s all done you can just sit back and enjoy those relaxing winter
            months. Before you know it the spring flowers will be up and blooming, the sun
            will be shining, the birds singing, bees buzzing and you’ll have your hands back in
            the dirt!

          </p>
        </div>
      </div>

      <div className="col col-md-12"><img
        src="/img_15.png" alt=""
        data-aos="fade-up" width="100%" className="aos-init aos-animate" />

        <div className="text-center"><small>Nice and tidy, fall cleanup
        </small></div>
      </div>


    </div>
  </>
)

const ServicePageContent = (
  <>
    <div style={{ boxShadow: '1px 4px 4px 1px rgba(0,0,0,5%)' }} className="row pt-5 ms-0 me-0">
      <div className="col-12 col-md-3 d-flex justify-content align-items-center align-content-center text-center">
        <div className="w-100">
          <HeaderItem>
            <>
              {/* <p className="green-text big text-center">RESEARCH</p> */}
              <p className="green-text big text-center">Landscape Services

              </p>
            </>
          </HeaderItem>
        </div>
      </div>

      <div className="col-12 col-md-3 text-center">
        <div data-aos="fade-up">

          {/* <p className="raw-text" data-aos="fade-up">
          </p> */}
          <ul className="text-start raw-text d-inline-block">
            <li>Planting</li>
            <li>Spring/Fall Cleanups</li>
            <li>Vegetable Garden Installation</li>
            <li>Mulch Installation</li>
            <li>Lawn - Sod Installation</li>

          </ul>
        </div>
      </div>
      <div className="col-12 col-md-3"><img
        src={veggieGarden} alt=""
        data-aos="fade-up" width="100%" className="aos-init aos-animate services-img" /></div>

      <div className="col-12 col-md-3 d-flex justify-content align-items-center">
        <div className="w-100 text-center" data-aos="fade-up">
          <ul className="text-start raw-text d-inline-block">
            <li>Lawn - Seed Installation</li>
            <li>Lawn - Overseeding</li>
            <li>Property maintenance</li>
            <li>Pressure Washing <br></br> (Driveways,walkways)</li>
          </ul>
        </div>
      </div>
    </div>

    <div style={{ boxShadow: '1px 4px 4px 1px rgba(0,0,0,5%)' }} className="row pt-5 ms-0 me-0">
      <div className="col-12 col-md-3 d-flex justify-content align-items-center align-content-center text-center">
        <div className="w-100">
          <HeaderItem>
            <>
              {/* <p className="green-text big text-center">RESTORE</p> */}
              <p className="green-text big text-center">Hardscapes

              </p>
            </>
          </HeaderItem>
        </div>
      </div>
      <div className="col-12 col-md-3"><img
        src={imagels1} alt=""
        data-aos="fade-up" width="100%" className="aos-init aos-animate services-img" />
      </div>
      {/* <div className="col-12 col-md-3 d-flex justify-content align-items-center">
        <div>

          <p className="raw-text" data-aos="fade-up">
          </p>
        </div>
      </div> */}




      <div className="col-12 col-md-3 d-flex justify-content align-items-center text-center">
        <div className="w-100 text-center" data-aos="fade-up">
          <ul className="text-start raw-text d-inline-block">
            <li>Custon Fences</li>
            <li>Arbours</li>
            <li>Pergolas</li>
            <li>Decks</li>
            <li>Privacy Screens</li>
          </ul>
        </div>
      </div>
      <div className="col-12 col-md-3"><img height='100%'
        src={imagels2} alt=""
        data-aos="fade-up" width="100%" className="aos-init aos-animate services-img" />
      </div>

    </div>

    <div style={{ boxShadow: '1px 4px 4px 1px rgba(0,0,0,5%)' }} className="row pt-5 ms-0 me-0">
      <div className="col-12 col-md-3 d-flex justify-content align-items-center align-content-center text-center">
        <div className="w-100">
          <HeaderItem>
            <>
              {/* <p className="green-text big text-center">REGENERATE</p> */}
              <p className="green-text big text-center">Irrigation</p>
            </>
          </HeaderItem>
        </div>
      </div>

      {/* <div className="col-12 col-md-3 d-flex justify-content align-items-center">
        <div>

          <p className="raw-text" data-aos="fade-up"></p>
        </div>
      </div> */}
      <div className="col-12 col-md-3"><img
        src={irriImage1} alt=""
        data-aos="fade-up" width="100%" className="aos-init aos-animate services-img" />
      </div>



      <div className="col-12 col-md-3 d-flex justify-content align-items-center">
        <div className="w-100 text-center" data-aos="fade-up">
          <ul className="text-start raw-text d-inline-block">
            <li>Irrigation System Install</li>
            <li>Irrigation Maintenance</li>

          </ul>
        </div>
      </div>

      <div className="col-12 col-md-3"><img
        src={irriImage2} alt=""
        data-aos="fade-up" width="100%" className="aos-init aos-animate services-img" />
      </div>

    </div>


    <div style={{ boxShadow: '1px 4px 4px 1px rgba(0,0,0,5%)' }} className="row pt-5 ms-0 me-0">
      <div className="col-12 col-md-3 d-flex justify-content align-items-center align-content-center text-center">
        <div className="w-100">
          <HeaderItem>
            <>
              {/* <p className="green-text big text-center">REGENERATE</p> */}
              <p className="green-text big text-center">Plumbing & Drainage </p>
            </>
          </HeaderItem>
        </div>
      </div>


      <div className="col-12 col-md-3 col-md-offset-3"><img
        src={Services1} alt=""
        data-aos="fade-up" height='100%' width="100%" className="aos-init aos-animate services-img" />
      </div>

      <div className="col-12 col-md-3 d-flex justify-content align-items-center">
        <div className="w-100 text-center" data-aos="fade-up">
          <ul className="text-start raw-text d-inline-block">
            <li>Perimeter Drains</li>
            <li>Sewer Lines</li>
            <li>Water Lines</li>
          </ul>
        </div>
      </div>

      <div className="col-12 col-md-3 col-md-offset-3"><img
        src={Services2} alt=""
        data-aos="fade-up" width="100%" className="aos-init aos-animate services-img" />
      </div>
    </div>


    <div style={{ boxShadow: '1px 4px 4px 1px rgba(0,0,0,5%)' }} className="row pt-5 ms-0 me-0">
      <div className="col-12 col-md-3 d-flex justify-content align-items-center align-content-center text-center">
        <div className="w-100">
          <HeaderItem>
            <>
              {/* <p className="green-text big text-center">REGENERATE</p> */}
              <p className="green-text big text-center">Delivery </p>
            </>
          </HeaderItem>
        </div>
      </div>


      <div className="col-12 col-md-3"><img
        src={Services3} alt=""
        data-aos="fade-up" width="100%" className="aos-init aos-animate services-img" />
      </div>

      <div className="col-12 col-md-3 d-flex justify-content align-items-center">
        <div className="w-100 text-center" data-aos="fade-up">
          <ul className="text-start raw-text d-inline-block">
            <li>Mulch</li>
            <li>Soil</li>
            <li>Gravel</li>
          </ul>
        </div>
      </div>
      <div className="col-12 col-md-3"><img
        src={Services4} height={'100%'} alt=""
        data-aos="fade-up" width="100%" className="aos-init aos-animate services-img" />
      </div>
    </div>
    {/*
    <div style={{boxShadow:'1px 4px 4px 1px rgba(0,0,0,5%)'}} className="row pt-5 ms-0 me-0">
      <div className="col-12 col-md-3 d-flex justify-content align-items-center align-content-center text-center">
        <div className="w-100">
          <HeaderItem>
            <>
              <p className="green-text big text-center">REGENERATE</p>
              <p className="green-text text-center">Sewer line</p>
            </>
          </HeaderItem>
        </div>
      </div>


      <div className="col-12 col-md-3 offset-md-3"><img
        src="https://renewlandscapes.com/regenerate.jpg?aa0f5bcee66af69c976f737bbd23a30b" alt=""
        data-aos="fade-up" width="100%" className="aos-init aos-animate"/></div>

      <div className="col-12 col-md-3 d-flex justify-content align-items-center">
        <div className="w-100 text-center" data-aos="fade-up">
          <ul className="text-start raw-text d-inline-block">
          </ul>
        </div>
      </div>
    </div>


    <div className="row pt-5 ms-0 me-0">
      <div className="col-12 col-md-3 d-flex justify-content align-items-center align-content-center text-center">
        <div className="w-100">
          <HeaderItem>
            <>
              <p className="green-text big text-center">REGENERATE</p>
              <p className="green-text text-center">Irrigation</p>
            </>
          </HeaderItem>
        </div>
      </div>


      <div className="col-12 col-md-3"><img
        src="https://renewlandscapes.com/regenerate.jpg?aa0f5bcee66af69c976f737bbd23a30b" alt=""
        data-aos="fade-up" width="100%" className="aos-init aos-animate"/></div>

      <div className="col-12 col-md-3 d-flex justify-content align-items-center text-center">
        <div className="w-100 text-center" data-aos="fade-up">
          <ul className="text-start raw-text d-inline-block">
            <li>Irrigation system install</li>
            <li>Irrigation maintenance</li>

          </ul>
        </div>
      </div>

    </div>

    <div className="row pt-5 ms-0 me-0">
      <div className="col-12 col-md-3 d-flex justify-content align-items-center align-content-center text-center">
        <div className="w-100">
          <HeaderItem>
            <>
              <p className="green-text big text-center">REGENERATE</p>
              <p className="green-text text-center">Material deliveries</p>
            </>
          </HeaderItem>
        </div>
      </div>


      <div className="col-12 col-md-3 offset-md-3"><img
        src="https://renewlandscapes.com/regenerate.jpg?aa0f5bcee66af69c976f737bbd23a30b" alt=""
        data-aos="fade-up" width="100%" className="aos-init aos-animate"/></div>


    </div> */}

    {/*<div className="row pt-5 ms-0 me-0">*/}


    {/*  <div className="col-12 col-md-3"><img*/}
    {/*    src="https://renewlandscapes.com/newflower.jpg?d967dc1fbb012e5e91ffbab70fb28de0" alt=""*/}
    {/*    data-aos="fade-up" width="100%" className="aos-init aos-animate"/></div>*/}
    {/*  <div className="col-12 col-md-3"><img*/}
    {/*    src="https://renewlandscapes.com/hardscape.jpg?7d5cea7ceec0ebd57e40aa2c96d9ec4a" alt=""*/}
    {/*    data-aos="fade-up" width="100%" className="aos-init aos-animate"/></div>*/}

    {/*  <div className="col-12 col-md-3 d-flex justify-content align-items-center align-content-center text-center">*/}
    {/*    <div className="w-100">*/}
    {/*      <HeaderItem>*/}
    {/*        <>*/}
    {/*          <p className="green-text big text-center">SERVICES</p>*/}
    {/*          <p className="green-text text-center">Hardscape and Softscape*/}
    {/*          </p>*/}
    {/*        </>*/}
    {/*      </HeaderItem>*/}
    {/*    </div>*/}
    {/*  </div>*/}

    {/*  <div className="col-12 col-md-3"><img*/}
    {/*    src="https://renewlandscapes.com/hardscape-2.jpg?bdf0c241aae35cbf725311e1994f62bf" alt=""*/}
    {/*    data-aos="fade-up" width="100%" className="aos-init aos-animate"/></div>*/}
    {/*</div>*/}

    {/* <div style={{height: '300px'}}></div> */}
  </>
)

const PageContent = ({ currentShowIndex, isForMobile = false }) => {
  return (<>
    <div className="page-content-wrapper mb-0">
      <div style={{ height: '50px' }}></div>

      {!isForMobile && <>
        {currentShowIndex === 1 && <>
          <div className="row pt-5 ms-0 me-0">
            <div className="col-3 d-flex justify-content align-items-center">

            </div>
            {/* <div className="col-3">
              <img src="/about_1.png" alt="" data-aos="fade-up"
                className="w-100 aos-init aos-animate" />
            </div> */}
            <div className="col-6">
              <HeaderItem>
                <>
                  <p className="green-text big text-center">WE BRING<br />NATURE HOME</p>

                </>
              </HeaderItem>

            </div>
            <div className="col-3"></div>
          </div>
          <div className="row pt-5 ms-0 me-0">
            <div className="col-3 d-flex justify-content align-items-center">

            </div>
            {/* <div className="col-3">
              <img src="/about_1.png" alt="" data-aos="fade-up"
                className="w-100 aos-init aos-animate" />
            </div> */}

            <div className="col-3">
              <p className="raw-text design-p-size" data-aos="fade-up">
              We strive to bring the love back to your garden.
                As outdoor enthusiasts ourselves, we understand the importance of creating a space where at the end of the day, you can kick back, relax and enjoy nature from the comfort of your own home.

                We will always treat your garden as if it was our own.
              </p>
              <img src={About1} alt="" data-aos="fade-up" width="100%" />
            </div>
            <div className="col-3">
              <img src="/about_1.png" alt="" data-aos="fade-up"
                className="w-100 aos-init aos-animate services-img" />
            </div>
            <div className="col-3"></div>
          </div>

          <div className="row pt-5 ms-0 me-0 justify-content-end">

            <div className="col-12 col-md-12 text-center">
              <MainTitleQuote>
                <>
                  One of our goals with clients is to <br />“make them fall back in love with their outdoor spaces”
                </>
              </MainTitleQuote>
            </div>

          </div>

          <div className="row pt-5 ms-0 me-0 justify-content-end mb-5">
            <div className="col-3">
              {/* <img src="https://renewlandscapes.com/design.jpg" alt="" data-aos="fade-up" width="100%" /> */}
            </div>
            <div className="col-6 pt-5 text-end">
              <img style={{ width: '100%' }} src={About2} alt="" data-aos="fade-up"
                width="75%" />
            </div>
            <div className="col-3"></div>
          </div>

          {/* <div style={{ height: '300px' }}></div> */}
        </>}


        {currentShowIndex === 2 && <>

          <div className="row pt-5 ms-0 me-0 justify-content-center">
            <div className="col-6 text-center">
              <HeaderItem>
                <p className="green-text big text-center" data-aos="fade-up">There are many different types of design,
                  we are here to find what fits you and your lifestyle best.
                </p>
              </HeaderItem>
            </div>

          </div>
          <div className="row pt-5 ms-0 me-0">
            <div className="col-3 d-flex justify-content align-items-center">
              <div>
                <HeaderItem>
                  <>
                    <p className="green-text big text-center">FORMAL GARDEN</p>
                    {/* <p className="green-text text-center">Using plants that occur naturally in the local environment</p> */}
                  </>
                </HeaderItem>

                <p className="raw-text design-p-size" data-aos="fade-up">A formal garden is a garden with a clear structure, geometric shapes and in most cases a symmetrical layout.
                </p>
              </div>
            </div>
            <div className="col-9"><img
              src={Design5}
              alt=""
              data-aos="fade-up" width="100%" className="aos-init aos-animate" /></div>
          </div>

          <div className="row pt-5 ms-0 me-0 justify-content-end">
            <div className="col-6">
              <img src={Design13} alt=""
                data-aos="fade-up"
                className="w-100 aos-init aos-animate" width="100%" />
            </div>
            <div className="col-3 d-flex align-items-center justify-content-center">
              <div>
                <HeaderItem>
                  <>
                    <p className="green-text big text-center">COTTAGE GARDEN

                    </p>
                    {/* <p className="green-text text-center">Plants for a future

                    </p> */}
                  </>
                </HeaderItem>

                <p className="raw-text design-p-size" data-aos="fade-up">The cottage garden is a distinct style that uses informal design, traditional materials, dense plantings, and a mixture of ornamental and edible plants.

                </p>
              </div>

            </div>
            <div className="col-3">
              <img src={Design6} alt=""
                data-aos="fade-up"
                className="w-100 h-100 aos-init aos-animate" width="100%" />
            </div>
          </div>

          <div className="row pt-5 ms-0 me-0">
            {/* <div className="col-3"><img
              src={Design8}
              alt=""
              data-aos="fade-up" width="100%" className="aos-init aos-animate" />
              </div> */}
            <div className="col-6 d-flex justify-content align-items-center">
              <div>
                <HeaderItem>
                  <>
                    <p className="green-text big text-center">TROPICAL GARDEN</p>
                    {/* <p className="green-text text-center">Using plants that occur naturally in the local environment</p> */}
                  </>
                </HeaderItem>

                <p className="raw-text design-p-size" data-aos="fade-up">A tropical garden is a type of garden that features tropical plants and requires heavy rainfall or a decent irrigation or sprinkler system for watering. These gardens typically need fertilizer and heavy mulching. Tropical gardens are no longer exclusive to tropical areas.
                </p>
              </div>
            </div>
            <div className="col-6"><img
              src={Design7}
              alt=""
              data-aos="fade-up" width="100%" className="aos-init aos-animate" />
            </div>
          </div>

          <div className="row pt-5 ms-0 me-0 justify-content-end">
            <div className="col-6">
              <img src={Design9} alt=""
                data-aos="fade-up"
                className="w-100 aos-init aos-animate" width="100%" />
            </div>
            <div className="col-6 d-flex align-items-center justify-content-center">
              <div>
                <HeaderItem>
                  <>
                    <p className="green-text big text-center">NATIVE GARDEN

                    </p>
                    {/* <p className="green-text text-center">Plants for a future

                    </p> */}
                  </>
                </HeaderItem>

                <p className="raw-text design-p-size" data-aos="fade-up">If you enjoy that wild look, perhaps a native garden would be just what you are looking for. Native plants make great additions to any garden,
                  requiring little maintenance and little to no watering once established. Choose native plants to promote biodiversity and healthy ecosystems in the region by creating habitat for local wildlife including migrating birds and pollinators.

                </p>
              </div>

            </div>
            {/* <div className="col-3">
              <img src={Design4} alt=""
                data-aos="fade-up"
                className="w-100 aos-init aos-animate" width="100%" />
            </div> */}
          </div>

          <div className="row pt-5 ms-0 me-0">
            <div className="col-3 d-flex justify-content align-items-center">
              <div>
                <HeaderItem>
                  <>
                    <p className="green-text big text-center">VEGETABLE GARDEN</p>
                    {/* <p className="green-text text-center">Using plants that occur naturally in the local environment</p> */}
                  </>
                </HeaderItem>

                <p className="raw-text design-p-size" data-aos="fade-up">Tired of the rising cost of produce? You can grow all your delicious fruits, veggies, greens and herbs all from home. These beds do require watering and daily care, but once you taste produce you have grown from home,
                  you’ll be hooked! For those of you with small spaces, but still want to grow a few veggies, no worries,  planters are also a great option.
                </p>
              </div>
            </div>
            <div className="col-9"><img
              src={Design10}
              alt=""
              data-aos="fade-up" width="100%" className="aos-init aos-animate" /></div>
          </div>

          <div className="row pt-5 ms-0 me-0 justify-content-end">
            <div className="col-6">
              <img src={Design12} alt=""
                data-aos="fade-up"
                className="w-100 aos-init aos-animate" width="100%" />
            </div>
            <div className="col-6 d-flex align-items-center justify-content-center">
              <div>
                <HeaderItem>
                  <>
                    <p className="green-text big text-center">PLANTERS

                    </p>
                    {/* <p className="green-text text-center">Plants for a future

                    </p> */}
                  </>
                </HeaderItem>

                <p className="raw-text design-p-size" data-aos="fade-up">
                  Need to beautify your front entrance, patio or business? We can design beautiful planters for any season.
                  Whatever designs you choose we will always do our best to work in pollinator friendly plants. We understand the importance of flowing with nature.
                </p>
              </div>

            </div>
            {/* <div className="col-3">
              <img src={Design4} alt=""
                data-aos="fade-up"
                className="w-100 aos-init aos-animate" width="100%" />
            </div> */}
          </div>

          {/* 
          <div className="row pt-5 ms-0 me-0 justify-content-end">
            <div className="col-3 d-flex align-items-center justify-content-center">
              <div>
                <HeaderItem>
                  <>
                    <p className="green-text big text-center">HERB GARDEN

                    </p>
                    <p className="green-text text-center">Plants for a future

                    </p>
                  </>
                </HeaderItem>

                <p className="raw-text" data-aos="fade-up">We can design an herb garden so you can harvest fresh herbs
                  for
                  culinary enhancement in the kitchen or even for medicinal purposes. From Improved Meyer Lemon Trees,
                  to
                  Peppermint, these useful plants are full of aroma and will tempt your taste buds while providing
                  annual
                  water savings and low maintenance.
                </p>
              </div>
            </div>

            <div className="col-9 d-flex align-items-center justify-content-center">
              <img src="https://renewlandscapes.com/cottage-garden.jpg?0fc26e0c42e08922e83a4bb7048a61d9" alt=""
                data-aos="fade-up"
                className="w-100 aos-init aos-animate" width="100%" />
            </div>
          </div> */}


          <div className="row pt-5 ms-0 me-0 justify-content-end">
            <div className="col-3 laptop-view-image">
              <img src={Design2} alt=""
                data-aos="fade-up"
                className="w-100 aos-init aos-animate" width="100%" height={'100%'} />
            </div>
            <div className="col-3 laptop-view-headeritem d-flex align-items-center justify-content-center">
              <div>
                <HeaderItem>
                  <>
                    <p className="green-text big text-center">LUSH AND DRY GARDEN


                    </p>
                    <p className="green-text text-center">A water-conserving approach to landscaping


                    </p>
                  </>
                </HeaderItem>

                <p className="raw-text design-p-size" data-aos="fade-up">After a few exceptionally dry years and record low water
                  tables,
                  drought-tolerant landscapes are becoming more and more popular. Many assume that this means growing
                  only
                  cactus and yuccas and covering the soil with gravel. Nothing could be further from the truth.
                  Water-wise
                  gardens are lush, dry gardens with year-round interest, long-lasting, hardy plants and a variety of
                  rich
                  textures.


                </p>
              </div>

            </div>
            <div className="col-6">
              <img src={Design1}
                alt=""
                data-aos="fade-up"
                className="w-100 aos-init aos-animate" height={'100%'} width="100%" />
            </div>


          </div>

          <div style={{ height: '300px' }}></div>
        </>}

        {currentShowIndex === 3 &&
          ServicePageContent
        }


        {currentShowIndex === 4 && <>
          {
            BlogPageContent
          }

          <div style={{ height: '300px' }}></div>
        </>}
      </>}

      {isForMobile && <>
        {currentShowIndex === 1 && <>
          <div className="row pt-5 ms-0 me-0 justify-content-center">
            <div className="col-10 text-center">
              <HeaderItem>
                <>
                  <p className="green-text big text-center">WE BRING<br />NATURE HOME</p>

                </>
              </HeaderItem>
            </div>
          </div>
          <div className="row pt-5 ms-0 me-0 justify-content-center">
            <div className="col-12 text-center">
              <p className="raw-text mt-5" data-aos="fade-up">
                The Maintenance phase is when Savage Landscapes grooms, feeds and
                maintains the landscape by hand, employing naturalistic pruning techniques, companion planting, and
                integrated, organic pest management.
              </p>
            </div>
            <div className="col-12">

              <img src="/about_1.png" alt=""
                data-aos="fade-up" width="100%" />
            </div>
          </div>

          <div className="row pt-5 ms-0 me-0 justify-content-center">

            <div className="col-12">
              <img src={About2} alt=""
                data-aos="fade-up" width="100%" />
            </div>
          </div>

          <div className="row pt-5 ms-0 me-0 justify-content-center">
            <div className="col-12 text-center p-3 mt-1">


              <MainTitleQuote>
                <>
                  One of our goals with clients is to <br />“make them fall back in love with their outdoor spaces”
                </>
              </MainTitleQuote>

            </div>
            <div className="col-12 text-center pt-5">
              <p className="raw-text mt-5" data-aos="fade-up">
                We strive to bring the love back to your garden.
                As outdoor enthusiasts ourselves, we understand the importance of creating a space where at the end of the day, you can kick back, relax and enjoy nature from the comfort of your own home.

                We will always treat your garden as if it was our own.
              </p>


            </div>
          </div>

          {/* <div className="row pt-5 ms-0 me-0 justify-content-center">
            <div className="col-6 text-center p-3 mt-1">


              <img height={432} style={{ objectFit: 'cover' }}
                src="https://renewlandscapes.com/about-colorful-plants.jpg?0ff3ab68f2b431a91d7f4949b0f13b11" alt=""
                data-aos="fade-up" width="100%" />

            </div>
            <div className="col-6 text-center pt-5">
            </div>
          </div> */}

          <div className="row pt-5 ms-0 me-0 justify-content-center">
            <div className="col-12 text-center p-3 mt-1">

              <img src="https://renewlandscapes.com/Uabout-beautiful-nlandscapes.jpg?5b5ca832f2b3313c1b1499a89e091b36"
                alt="" data-aos="fade-up" width="100%" />

            </div>
          </div>


          <div style={{ height: '100px' }}></div>
        </>}


        {currentShowIndex === 2 && <>

          <div className="row pt-5 ms-0 me-0 justify-content-center">
            <div className="col-12 text-center">
              <HeaderItem>
                <p className="green-text big text-center" data-aos="fade-up">There are many different types of design,
                  we are here to find what fits you and your lifestyle best.
                </p>
              </HeaderItem>
            </div>

          </div>

          <div className="row pt-5 ms-0 me-0 justify-content-center">
            <div className="col-10 text-center">
              <HeaderItem>
                <>
                  <p className="green-text big text-center">FORMAL GARDEN</p>
                  {/* <p className="green-text text-center">Using plants that occur naturally in the local environment</p> */}
                </>
              </HeaderItem>
            </div>
          </div>
          <div className="row pt-5 ms-0 me-0 justify-content-center">
            <div className="col-6 text-center">
              <p className="raw-text mt-5" data-aos="fade-up">A formal garden is a garden with a clear structure, geometric shapes and in most cases a symmetrical layout.</p>
            </div>
            <div className="col-6">
              <img src={Design3} alt=""
                data-aos="fade-up" width="100%" />
            </div>
          </div>

          <div className="row pt-5 ms-0 me-0 justify-content-center">

            <div className="col-12">
              <img src={Design5}
                alt=""
                data-aos="fade-up" width="100%" />
            </div>
          </div>


          <div className="row pt-5 ms-0 me-0 justify-content-center">
            <div className="col-10 text-center">
              <HeaderItem>
                <>
                  <p className="green-text big text-center">COTTAGE GARDEN</p>
                  {/* <p className="green-text text-center">Plants for a future</p> */}
                </>
              </HeaderItem>
            </div>
          </div>

          <div className="row pt-5 ms-0 me-0 justify-content-center">
            <div className="col-6 text-center">
              <p className="raw-text mt-5" data-aos="fade-up">
                We can design an herb garden so you can harvest fresh herbs for culinary enhancement in the kitchen or
                even for medicinal purposes. From Improved Meyer Lemon Trees, to Peppermint, these useful plants are
                full of aroma and will tempt your taste buds while providing annual water savings and low
                maintenance.</p>
            </div>
            <div className="col-6 mt-auto mb-auto">
              <img src={Design6} alt=""
                data-aos="fade-up" width="100%" />
            </div>
          </div>

          <div className="row pt-5 ms-0 me-0 justify-content-center">

            <div className="col-12">
              <img src="https://renewlandscapes.com/cottage-garden.jpg?0fc26e0c42e08922e83a4bb7048a61d9"
                alt=""
                data-aos="fade-up" width="100%" />
            </div>
          </div>

          <div className="row pt-5 ms-0 me-0 justify-content-center">
            <div className="col-10 text-center">
              <HeaderItem>
                <>
                  <p className="green-text big text-center">TROPICAL GARDEN</p>
                  {/* <p className="green-text text-center">Using plants that occur naturally in the local environment</p> */}
                </>
              </HeaderItem>
            </div>
          </div>
          <div className="row pt-5 ms-0 me-0 justify-content-center">
            <div className="col-6 text-center">
              <p className="raw-text mt-5" data-aos="fade-up">A tropical garden is a type of garden that features tropical plants and requires heavy rainfall or a decent irrigation or sprinkler system for watering. These gardens typically need fertilizer and heavy mulching. Tropical gardens are no longer exclusive to tropical areas.</p>
            </div>
            <div className="col-6 mb-auto mt-auto">
              <img src={Design7} alt=""
                data-aos="fade-up" width="100%" />
            </div>
          </div>

          {/* <div className="row pt-5 ms-0 me-0 justify-content-center">

            <div className="col-12">
              <img src={veggieGarden}
                alt=""
                data-aos="fade-up" width="100%" />
            </div>
          </div> */}



          <div className="row pt-5 ms-0 me-0 justify-content-center">
            <div className="col-10 text-center">
              <HeaderItem>
                <>
                  <p className="green-text big text-center">COTTAGE GARDEN</p>
                  {/* <p className="green-text text-center">English in origin, with grace and charm</p> */}
                </>
              </HeaderItem>
            </div>
          </div>

          <div className="row pt-5 ms-0 me-0 justify-content-center">
            <div className="col-6 text-center">
              <p className="raw-text mt-5" data-aos="fade-up">The cottage garden is a distinct style that uses informal design, traditional materials, dense plantings, and a mixture of ornamental and edible plants.</p>
            </div>
            <div className="col-6">
              <img src={Design6} alt=""
                data-aos="fade-up" width="100%" />
            </div>
          </div>

          <div className="row pt-5 ms-0 me-0 justify-content-center">

            <div className="col-12">
              <img src={Design13}
                alt=""
                data-aos="fade-up" width="100%" />
            </div>
          </div>


          <div className="row pt-5 ms-0 me-0 justify-content-center">
            <div className="col-10 text-center">
              <HeaderItem>
                <>
                  <p className="green-text big text-center">LUSH AND DRY GARDEN</p>
                  <p className="green-text text-center">A water-conserving approach to landscaping</p>
                </>
              </HeaderItem>
            </div>
          </div>

          <div className="row pt-5 ms-0 me-0 justify-content-center">
            <div className="col-12 text-center">
              <p className="raw-text mt-5" data-aos="fade-up">
                After a few exceptionally dry years and record low water tables, drought-tolerant landscapes are
                becoming more and more popular. Many assume that this means growing only cactus and yuccas and covering
                the soil with gravel. Nothing could be further from the truth. Water-wise gardens are lush, dry gardens
                with year-round interest, long-lasting, hardy plants and a variety of rich textures.</p>
            </div>
            {/* <div className="col-6">
              <img src={Design2} alt=""
                data-aos="fade-up" width="100%" />
            </div> */}
          </div>

          <div className="row pt-5 ms-0 me-0 justify-content-center">

            <div className="col-12">
              <img src={About2}
                alt=""
                data-aos="fade-up" width="100%" />
            </div>
          </div>


          <div style={{ height: '100px' }}></div>
        </>}

        {currentShowIndex === 3 &&
          ServicePageContent
        }


        {currentShowIndex === 4 && <>
          {
            BlogPageContent
          }
          <div style={{ height: '100px' }}></div>
        </>}
      </>}

    </div>
    {isForMobile && <div className="white-bg for-mobile">
      <div className="contact-content  d-block d-lg-none">
        <p className="big-text text-center">Contact Us</p>
        <form action="/submit" method="post">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group"><input type="text" id="name" placeholder="First Name" name="name"
                  className="form-control" />
                  <div data-lastpass-icon-root="true"></div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group"><input type="text" placeholder="Last Name" name="last"
                  className="form-control" /></div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group"><input type="text" id="email" placeholder="Enter email" name="email"
                  className="form-control" /></div>
              </div>
              <div className="col-md-6">
                <div className="form-group"><input type="tel" placeholder="Phone" name="phone"
                  className="form-control" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group"><textarea type="text" id="mess" placeholder="Message" name="mess"
                  className="form-control" /></div>
              </div>
            </div>
            <button type="submit" className="btn btn-black footer-btn-send">SEND</button>
          </div>
        </form>
      </div>

      <div className="mobile-footer text-center"><img
        src={Logo} alt="" />
        <p style={{ fontSize: '1em' }}
          className="my-5 raw-text">LOCATED IN 926 Dunford Ave. Victoria,
          BC V9B 2S3</p> <p className="mb-5"><a href="tel:2508803433"
            className="tel">(250) 880 3433</a></p>
        {/* <div className="footer-icons"><a href="#">

          <img src={houzzIcon} alt="" /></a>
          <a href="#"><img src={fbIcon}
            alt="" /></a>
          <a href="#"><img
            src={instagramIcon} alt="" /></a></div> */}


      </div>
    </div>}
  </>);
};

PageContent.propTypes = {};

export default PageContent;
