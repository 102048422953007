class Utils {
  static getItemTitle(itemIndex) {
    switch (itemIndex) {
      case 1:
        return 'About'
      case 2:
        return 'Design'
      case 3:
        return 'Services'
      case 4:
        return 'Blog'

    }
  }

  static getBlackBox(itemIndex, isForMobile) {
    switch (itemIndex) {
      case 1:
        return <div className="black-box-inner">
          <p>ABOUT US</p>
          <div className="big-text">
            {/* WHERE GREEN THUMB GOES HAND IN HAND WITH GREAT CARE */}
          </div>
          <div className="text-center">
            <hr className="d-inline-block" />
          </div>
          <p>
            Welcome to Savage Landscapes! We are a fully licensed and insured team of skilled and passionate
            landscapers, with over a decade of experience in the industry. Our mission is to help homeowners and
            businesses transform their outdoor spaces into beautiful, functional, and sustainable environments, ensuring
            that your vision becomes a reality. I’m Dan Ahron, the owner and operator of Savage Landscapes, serivcing
            Victoria and the surrounding area, British Columbia. Alongside my partner, Sarah Savage, whose name was the
            obvious choice for the company, we aim to unlock the full potential of your outdoor space. Sarah has over a
            decade of hands-on experience, fuses creativity with her strong horticulture background, landscaping design
            and maintenance to procure your exterior vision. Together, we are dedicated to working with you to ensure
            the highest-quality, work ethic, and exceptional client service, and bring your creations to life. We offer
            a wide range of services: Garden design and installation, Annual pruning, Spring and Fall cleanup, Lawn
            care, Irrigation System Installation and Maintenance, Hardscaping. Our team members have a strong
            understanding of horticulture and the local climate, and we use this knowledge to choose plants and
            materials that are well-suited for your specific needs and environment. We are committed to using
            eco-friendly practices and materials whenever possible. We take great pride in the relationships we build
            with our clients, and in the care of transformations we bring to fruition of your outdoor spaces. We look
            forward to working with you to bring new life to your outdoor space. Thank you for considering us for your
            landscaping needs!
          </p>
        </div>
      case 2:
        return <div className="black-box-inner h-100">
          <p>LANDSCAPE DESIGN</p>
          <div className="big-text">
            We aim to work with you to create your dream outdoor space for you to enjoy for years to come.
          </div>
          <div className="text-center">
            <hr className="d-inline-block" />
          </div>
          <p className="design-p-height">
            All beautiful gardens begin with great landscape design, The Savage Landscapes team are here to help you from start to finish.
          </p>
          <p className="design-p-height">
            First we will have a site visit and discuss what you are envisioning for your outdoor space:
          </p>
          <div>


          <div className="row text-left">

            <div className="col-md-1 col-0"></div>          
            <div className="col-md-5 col-12">
              <ul >
                <li className="design-li design-p-height">Is it kick back and relax or to entertain?</li>
                <li className="design-li design-p-height">What is your personal style preference?</li>
                <li className="design-li design-p-height">Colour Palette?</li>

              </ul>
            </div>

            <div className="col-md-5 col-12">
              <ul>
                <li className="design-li design-p-height">Is it a high traffic area?</li>
                <li className="design-li design-p-height">Do you have or need irrigation?</li>
                <li className="design-li design-p-height">And finally, budget.</li>
              </ul>
            </div>
            <div className="col-md-1 col-0"></div> 
          </div>
   

          </div>

          <p className="design-p-height">Once we have established your vision and budget, the designing can begin!</p>
          <p className="design-p-height">When we present your design to you, you will recieve a landscape plan along with a visual skatch. 
            The visual sketch will represent what the garden will look like once the plants have fully established, followed with proper maintenance.
            </p>
        <p className="design-p-height">From here the final preparations are madem, and it is time to bring your landscape design to life.</p>
        <p className="design-p-height">On behalf of myself and the Savage Landscapes team, We look forward to working with you.</p>

        </div>
      case 3:
        return <div className="black-box-inner">
          {/* <p>OUR DISTINCT SERVICES

          </p> */}
          <div className="big-text">
          OUR DISTINCT SERVICES
          </div>
          <div className="text-center">
            <hr className="d-inline-block" />
          </div>
          <p>
            We offer our many services to set your mind at ease, and to transform your home into a space where you can sit back, relax and enjoy.

            <br />

            <br />
            Give us thirty minutes of your time, and we’ll show you we can improve your surroundings.
          </p>
        </div>
      case 4:
        return <div className="black-box-inner">
          <p>WELCOME TO MY BLOG

          </p>
          <div className="big-text">
          Here you will find all the tips and tricks to help you become a successful gardner.

          </div>
          <div className="text-center">
            <hr className="d-inline-block" />
          </div>
          <p>
            Our ecological vision continues to grow as we change conventional landscaping into how we view the
            relationship between nature, the environment and ourselves. 

          </p>
        </div>

    }
  }

  static gotoPageByIndex(itemIndex, navigate) {
    switch (itemIndex) {
      case 1:
        return navigate('/about')
      case 2:
        return navigate('/design')
      case 3:
        return navigate('/services')
      case 4:
        return navigate('/blog')

    }
  }
}

export default Utils;
