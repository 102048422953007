import React from 'react';
import PropTypes from 'prop-types';

const MainTitleQuote = ({children}) => {
  return (
    <p className="green-text big fst-italic">
      {children}
    </p>
  );
};

MainTitleQuote.propTypes = {

};

export default MainTitleQuote;
