import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './styles.scss'
import {Link} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Logo from '../mainpage/assets/renew-landscapes.png';

const MainMenu = ({isActive, onClickCallback, toggleContact}) => {

  const [isShowFullMenu, setIsShowFullMenu] = useState(false);
  const navigate = useNavigate();
  return (

    <>
      <div className={`menu-panel left ${isShowFullMenu && 'active'}`}>
        <div className="lines">
          <div className="line"></div>
          <div className="line ms-50-percents"></div>
        </div>
        <Link onClick={() => {
          setIsShowFullMenu(false);
        }} className="menu-panel-item" type="span" to="/about">About</Link>

        <Link onClick={() => {
          setIsShowFullMenu(false);
        }} className="menu-panel-item" type="span" to="/design">Design</Link>

        <Link onClick={() => {
          setIsShowFullMenu(false);
        }} className="menu-panel-item" type="span" to="/services">Services</Link>

        {/*<span className="menu-panel-item">About</span>*/}
        {/*<span className="menu-panel-item">Design</span>*/}
        {/*<span className="menu-panel-item">Services</span>*/}
      </div>
      <div style={{height:"100%"}}
        className={`home-col three main-col d-flex flex-column justify-content-around main-menu ${isActive && 'active'}`}>


        


        <div onClick={() => {

          if (isActive) {
            // onClickCallback(null);
            navigate('/');
          } else {
            setIsShowFullMenu(!isShowFullMenu);
          }
        }} className={`menu-button ${(isShowFullMenu || isActive) && 'active'}`}>
          <span></span>
          <span></span>
          <span></span>
        </div>



        <div className="ms-2 me-2">
          <img src={Logo} alt="" className="w-100"/>
        </div>

        <p className="green-text text-center">CREATE. ENHANCE. MAINTAIN.</p>

        <Link onClick={() => {
          setIsShowFullMenu(false);
        }} className="menu-panel-item" type="span" to="/contact">
          <i className="cursor-pointer fas fa-envelope fa-2x green contact-button"></i>
        </Link>


        <div className="text-center"><p className="green-text">
          926 Dunford Ave. Victoria, <br/>
          BC V9B 2S3
        </p> <p className="green-text">(250) 880 3433</p>
        </div>
      </div>
      <div className={`menu-panel right ${isShowFullMenu && 'active'}`}>
        <div className="lines">
          <div className="line me-50-percents"></div>
          <div className="line "></div>
        </div>
        <Link onClick={() => {
          setIsShowFullMenu(false);
        }} className="menu-panel-item" type="span" to="/blog">Blog</Link>

        <Link onClick={() => {
          setIsShowFullMenu(false);
        }} className="menu-panel-item" type="span" to="/gallery">Gallery</Link>

        <Link onClick={() => {
          setIsShowFullMenu(false);
        }} className="menu-panel-item" type="span" to="/contact">Contact</Link>

        {/*<span className="menu-panel-item">Gallery</span>*/}
        {/*<span className="menu-panel-item">Contact</span>*/}
      </div>
    </>


  );
};

MainMenu.propTypes = {};

export default MainMenu;
