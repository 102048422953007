import React from 'react';
import PropTypes from 'prop-types';

const HeaderItem = ({children, isForMobile = false}) => {
  return (<div className={`header-item text-center ${isForMobile ? 'border-div' : ''}`} data-aos="fade-up">
      {children}
      <div className="divider"></div>
    </div>);
};

HeaderItem.propTypes = {};




export default HeaderItem;
