import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import './styles.scss'
import PageContent from "../pagecontent/PageContent";
import Utils from "../Utils";
import {useNavigate} from "react-router-dom";

const PageItem = ({isForMobile = false, currentShowIndex, itemIndex, onClickCallback}) => {
  const isShowingPageMode = useMemo(() => {
    return currentShowIndex !== null;
  }, [currentShowIndex, itemIndex]);

  const navigate = useNavigate();


  const isActiveMode = useMemo(() => {
    return currentShowIndex === itemIndex;
  }, [currentShowIndex, itemIndex]);

  const customClassString = useMemo(() => {
    if (isShowingPageMode) {

      if (isForMobile) {
        if (isActiveMode) return 'w-100 active';
        return 'h-0 leaver';
      } else {
        if (isActiveMode) return 'w-100 active';
        return 'w-0';
      }

    }
    return "";
  }, [isActiveMode, isShowingPageMode, isForMobile]);

  return (
    <div className={`home-col page-item one clickable-col page-key-${itemIndex} ${customClassString} ${isForMobile ? 'for-mobile' : ''}` } onClick={() => {
      // onClickCallback(itemIndex)

      Utils.gotoPageByIndex(itemIndex, navigate);

    }}>
      <div className="col-label">
        {Utils.getItemTitle(itemIndex).toUpperCase()}
      </div>

      {isActiveMode && <>
        <div className="black-box animate__animated animate__backInDown">
          {Utils.getBlackBox(itemIndex, isForMobile)}
        </div>
      </>}


    </div>);
};

PageItem.propTypes = {};

export default PageItem;
